.bicpl-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.bicpl-node .rot0 .react-flow__handle-bottom {
  bottom: 17px;
}

.bicpl-node .rot2 .react-flow__handle-top {
  top: 17px;
}

.bicpl-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.bicpl-node.rot1 svg text {transform: rotate(-90deg) translate(-29px, -25px); text-anchor: middle;}
.bicpl-node.rot2 svg text {transform: rotate(-180deg) translate(-8px, -56px);}
.bicpl-node.rot3 svg text {transform: rotate(90deg) translate(24px, -32px);}


.bicpl-node.rot0 svg {transform: rotate(0deg);}
.bicpl-node.rot1 svg {transform: rotate(90deg);}
.bicpl-node.rot2 svg {transform: rotate(180deg);}
.bicpl-node.rot3 svg {transform: rotate(-90deg);}


.bicpl-node .diagram-id {position: absolute;}
.bicpl-node .label {position: absolute;}


.bicpl-node.rot0 .diagram-id {top: 0%;}
.bicpl-node.rot2 .diagram-id {top: 15%;}
.bicpl-node.rot1 .diagram-id {left: 80%; top: 20%;}
.bicpl-node.rot3 .diagram-id {left: 67%; top: 20%;}

.bicpl-node.rot0 .label {left: 40%; top: 60%;}
.bicpl-node.rot2 .label {left: 40%; top: 73%;}
.bicpl-node.rot1 .label {left: 80%; top: 40%;}
.bicpl-node.rot3 .label {left: 67%; top: 40%;}

/* .unicpl-node.rot0, .unicpl-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.unicpl-node.rot1, .unicpl-node.rot3 {
  width: var(--height);
  height: var(--width);
}
/*
.unicpl-node .diagram-id {
  padding-left: 5px;
} */
