.source-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;

}
.source-node svg {
  height: 100%;
}
.source-node.rot0 svg {transform: rotate(0deg);}
.source-node.rot1 svg {transform: rotate(90deg);}
.source-node.rot2 svg {transform: rotate(180deg);}
.source-node.rot3 svg {transform: rotate(-90deg);}




.source-node .diagram-id {position: absolute;}
.source-node .label {position: absolute;}


.source-node.rot0 .diagram-id {top: -30%;}
.source-node.rot2 .diagram-id {top: -30%;}
.source-node.rot1 .diagram-id {left: 100%;top:10%;}
.source-node.rot3 .diagram-id {left: 100%;top:10%;}

.source-node.rot0 .label {top: 90%;}
.source-node.rot2 .label {top: 90%;}
.source-node.rot1 .label {left: 100%; top:70%;}
.source-node.rot3 .label {left: 100%; top:70%;}




/* .source-node.rot1 {background: url('./icons/Source_B.svg');background-repeat: no-repeat;}
.source-node.rot2 {background: url('./icons/Source_L.svg');background-repeat: no-repeat;}
.source-node.rot3 {background: url('./icons/Source_T.svg');background-repeat: no-repeat;} */

.source-node svg{
  width: var(--width);
  height: var(--height);
}
