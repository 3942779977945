@keyframes slide-fwd-top {
  0% {
    transform: translateX(0) translateY(0px) rotate(30deg);
  }
  100% {
    transform: translateX(10.919px) translateY(-30px) rotate(20deg);
  }
}

.slide-fwd-top {
  animation: slide-fwd-top 1s cubic-bezier(0.455, 0.030, 0.515, 0.955)  infinite alternate both;
}
