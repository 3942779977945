.bounce-6 {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: bounce-6;
    animation-timing-function: ease;
}
@keyframes bounce-6 {
    0%   { transform: scale(-1,1)      translateY(0); }
    10%  { transform: scale(-1.1,.9)   translateY(0); }
    30%  { transform: scale(-.9,1.1)   translateY(-0.75rem); }
    50%  { transform: scale(-1.05,.95) translateY(0); }
    57%  { transform: scale(-1,1)      translateY(-0.075rem); }
    64%  { transform: scale(-1,1)      translateY(0); }
    100% { transform: scale(-1,1)      translateY(0); }
}
