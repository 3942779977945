.xfer-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.xfer-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.xfer-node.rot1 svg text {transform: rotate(-90deg) translate(-29px, -20px);}
.xfer-node.rot2 svg text {transform: rotate(-180deg) translate(-4px, -50px);}
.xfer-node.rot3 svg text {transform: rotate(90deg) translate(21px, -25px);}



.xfer-node svg{
  width: 100%;
  height: auto;
}

.xfer-node.rot0 svg {transform: rotate(0deg);}
.xfer-node.rot1 svg {transform: rotate(90deg);}
.xfer-node.rot2 svg {transform: rotate(180deg);}
.xfer-node.rot3 svg {transform: rotate(-90deg);}


.xfer-node .diagram-id {position: absolute;}
.xfer-node .label {position: absolute;}


.xfer-node.rot0 .diagram-id {top: -10%;}
.xfer-node.rot2 .diagram-id {top: -10%;}
.xfer-node.rot1 .diagram-id {left: 90%; top: 20%;}
.xfer-node.rot3 .diagram-id {left: 90%; top: 20%;}

.xfer-node.rot0 .label {top: 85%;}
.xfer-node.rot2 .label {top: 85%;}
.xfer-node.rot1 .label {left: 90%; top: 70%;}
.xfer-node.rot3 .label {left: 90%; top: 70%;}

/* .xfer-node.rot0, .xfer-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.xfer-node.rot1, .xfer-node.rot3 {
  width: var(--height);
  height: var(--width);
}

.xfer-node .diagram-id {
  padding-left: 5px;
} */
