.splitter-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.splitter-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.splitter-node.rot1 svg text {transform: rotate(-90deg) translate(-24px, -20px);}
.splitter-node.rot2 svg text {transform: rotate(-180deg) translate(-5px, -45px);}
.splitter-node.rot3 svg text {transform: rotate(90deg) translate(20px, -24px);}


.splitter-node.rot0 svg {transform: rotate(0deg);}
.splitter-node.rot1 svg {transform: rotate(90deg);}
.splitter-node.rot2 svg {transform: rotate(180deg);}
.splitter-node.rot3 svg {transform: rotate(-90deg);}


.splitter-node .diagram-id {position: absolute;}
.splitter-node .label {position: absolute;}


/* .splitter-node.rot0 .diagram-id {top: -10%;}
.splitter-node.rot2 .diagram-id {top: -10%;}
.splitter-node.rot1 .diagram-id {left: 90%; top: 20%;}
.splitter-node.rot3 .diagram-id {left: 90%; top: 20%;} */

.splitter-node.rot0 .label {top: 85%;}
.splitter-node.rot2 .label {top: 85%;}
.splitter-node.rot1 .label {left: 90%; top: 70%;}
.splitter-node.rot3 .label {left: 90%; top: 70%;}

/* .splitter-node.rot0, .splitter-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.splitter-node.rot1, .splitter-node.rot3 {
  width: var(--height);
  height: var(--width);
}

.splitter-node .diagram-id {
  padding-left: 5px;
} */
