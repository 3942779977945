.flow-node {
  /* background: #DFDDBA; */
  color: #FFF;
  display: block;
  text-align: center;
  position: relative;
}
/* 
.selected .flow-node {
  box-shadow: 0 0 0.4rem 0.2rem #FF9C08;
} */
.flow-node .label{
  margin-top: 2px;
  font-size: 11px;
  text-align: center;
}
.flow-node .diagram-id{
  display: flex;
  white-space: nowrap;
  flex: 0 0 auto;
}

.selected .flow-node svg {
  filter: drop-shadow(0 0 0.4rem #FF9C08) !important;
}

.nopart.flow-node svg {
  filter: drop-shadow(0 0 0.4rem #ff0808);
}


.flow-node .diagram-id {
  color: black;
}

.flow-node .label {
  padding: 0px;
  color: black;
  position: absolute;
  top: 100%;
  text-align: left;
}

/*
TODO: Figure out how to override CSS without !important.
The CSS ordering is different in the production build than in dev.
*/
.react-flow__handle {
  width: 8px !important;
  height: 8px !important;
}
.react-flow__handle.source {
  background: #5c5454 !important;
}
.react-flow__handle.target {
  background: #5c5454 !important;
}

.react-flow__edge-path {
  stroke-width: 5px !important;
  cursor: pointer !important;
}
.selected .react-flow__edge-path {
  stroke: #FF9C08 !important;
}