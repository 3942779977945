.load-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;

}
.load-node.rot0 svg {transform: rotate(0deg);}
.load-node.rot1 svg {transform: rotate(90deg);}
.load-node.rot2 svg {transform: rotate(180deg);}
.load-node.rot3 svg {transform: rotate(-90deg);}


.load-node .diagram-id {position: absolute;}
.load-node .label {position: absolute;}

.load-node.rot0 .diagram-id {top: -15%;}
.load-node.rot2 .diagram-id {top: -15%;}
.load-node.rot1 .diagram-id {left: 81%;; top: 0%;}
.load-node.rot3 .diagram-id {left: 81%;; top: 0%;}

.load-node.rot0 .label {top: 75%;}
.load-node.rot2 .label {top: 75%;}
.load-node.rot1 .label {left: 81%; top: 70%;}
.load-node.rot3 .label {left: 81%; top: 70%;}

/* .load-node.rot0 {background: url('./icons/Load_L.svg');background-repeat: no-repeat;}
.load-node.rot1 {background: url('./icons/Load_T.svg');background-repeat: no-repeat;}
.load-node.rot2 {background: url('./icons/Load_R.svg');background-repeat: no-repeat;}
.load-node.rot3 {background: url('./icons/Load_B.svg');background-repeat: no-repeat;}

.load-node.rot0, .load-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.load-node.rot1, .load-node.rot3 {
  width: var(--height);
  height: var(--width);
} */