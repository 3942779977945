.cable-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;

}
.cable-node.rot0 svg {transform: rotate(0deg);}
.cable-node.rot1 svg {transform: rotate(90deg);}
.cable-node.rot2 svg {transform: rotate(180deg);}
.cable-node.rot3 svg {transform: rotate(-90deg);}


.cable-node .diagram-id {position: absolute;}
.cable-node .label {position: absolute;}


.cable-node.rot0 .diagram-id {top: -10%;}
.cable-node.rot2 .diagram-id {top: -10%;}
.cable-node.rot1 .diagram-id {left: 80%; top: 0%}
.cable-node.rot3 .diagram-id {left: 80%; top: 0%}

.cable-node.rot0 .label {top: 70%;}
.cable-node.rot2 .label {top: 70%;}
.cable-node.rot1 .label {left: 80%; top: 70%;}
.cable-node.rot3 .label {left: 80%; top: 70%;}

/* .cable-node.rot0,.cable-node.rot2 {background: url('./icons/Cable_1.svg');}
.cable-node.rot1,.cable-node.rot3 {background: url('./icons/Cable_2.svg');}


.cable-node.rot0, .cable-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.cable-node.rot1, .cable-node.rot3 {
  width: var(--height);
  height: var(--width);
} */