.sp6t-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.sp6t-node svg {
  width: 100%;
}

.sp6t-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.sp6t-node.rot1 svg text {transform: rotate(-90deg) translate(-90px, -80px);}
.sp6t-node.rot2 svg text {transform: rotate(-180deg) translate(-5px, -170px);}
.sp6t-node.rot3 svg text {transform: rotate(90deg) translate(80px, -85px);}


.sp6t-node.rot0 svg {transform: rotate(0deg);}
.sp6t-node.rot1 svg {transform: rotate(90deg);}
.sp6t-node.rot2 svg {transform: rotate(180deg);}
.sp6t-node.rot3 svg {transform: rotate(-90deg);}


.sp6t-node .diagram-id {position: absolute;}
.sp6t-node .label {position: absolute;}


.sp6t-node.rot0 .diagram-id {top: -76%; left: 40%;}
.sp6t-node.rot2 .diagram-id {top: -77%; left: 27%;}
.sp6t-node.rot1 .diagram-id {right: -65%; top: 40%;}
.sp6t-node.rot3 .diagram-id {right: -63%; top: 40%;}

.sp6t-node.rot0 .label {top: 160%;}
.sp6t-node.rot2 .label {top: 160%;}
.sp6t-node.rot1 .label {left: -107%; top: 40%;}
.sp6t-node.rot3 .label {left: -107%; top: 40%;}
