.attenuator-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.attenuator-node.rot0 svg {transform: rotate(0deg);}
.attenuator-node.rot1 svg {transform: rotate(90deg);}
.attenuator-node.rot2 svg {transform: rotate(180deg);}
.attenuator-node.rot3 svg {transform: rotate(-90deg);}


.attenuator-node .diagram-id {position: absolute;}
.attenuator-node .label {position: absolute;}


.attenuator-node.rot0 .diagram-id {top: -25%;}
.attenuator-node.rot2 .diagram-id {top: -25%;}
.attenuator-node.rot1 .diagram-id {left: 90%; top: 0%;}
.attenuator-node.rot3 .diagram-id {left: 90%; top: 0%;}

.attenuator-node.rot0 .label {top: 85%;}
.attenuator-node.rot2 .label {top: 85%;}
.attenuator-node.rot1 .label {left: 90%; top: 70%;}
.attenuator-node.rot3 .label {left: 90%; top: 70%;}



/* .attenuator-node.rot0,.attenuator-node.rot2 {background: url('./icons/Attenuator-rot0.bmp');}
.attenuator-node.rot1,.attenuator-node.rot3 {background: url('./icons/Attenuator-rot1.bmp');}

 
.attenuator-node.rot0, .attenuator-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.attenuator-node.rot1, .attenuator-node.rot3 {
  width: var(--height);
  height: var(--width);
} */
