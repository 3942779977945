.bulkhead-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;

}
.bulkhead-node.rot0 svg {transform: rotate(0deg);}
.bulkhead-node.rot1 svg {transform: rotate(90deg);}
.bulkhead-node.rot2 svg {transform: rotate(180deg);}
.bulkhead-node.rot3 svg {transform: rotate(-90deg);}


.bulkhead-node .diagram-id {position: absolute;}
.bulkhead-node .label {position: absolute;}


.bulkhead-node.rot0 .diagram-id {top: -15%;left: 35%;}
.bulkhead-node.rot2 .diagram-id {top: -15%;left: 35%;}
.bulkhead-node.rot1 .diagram-id {left: 80%; top: 10%;}
.bulkhead-node.rot3 .diagram-id {left: 80%; top: 10%;}

.bulkhead-node.rot0 .label {top: 75%;}
.bulkhead-node.rot2 .label {top: 75%;}
.bulkhead-node.rot1 .label {left: 80%; top: 60%;}
.bulkhead-node.rot3 .label {left: 80%; top: 60%;}
/* 
.bulkhead-node.rot0,.bulkhead-node.rot2 {background: url('./icons/Bulkhead_1.svg');}
.bulkhead-node.rot1,.bulkhead-node.rot3 {background: url('./icons/Bulkhead_2.svg');}



.bulkhead-node.rot0, .bulkhead-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.bulkhead-node.rot1, .bulkhead-node.rot3 {
  width: var(--height);
  height: var(--width);
} */