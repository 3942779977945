.sp12t-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.sp12t-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px); text-anchor: start;}
.sp12t-node.rot1 svg text {transform: rotate(-90deg) translate(-176px, -168px); text-anchor: middle;}
.sp12t-node.rot2 svg text {transform: rotate(-180deg) translate(-5px, -344px); text-anchor: start;}
.sp12t-node.rot3 svg text {transform: rotate(90deg) translate(169px, -171px); text-anchor: end;}

.sp12t-node.rot0 svg {transform: rotate(0deg);}
.sp12t-node.rot1 svg {transform: rotate(90deg);}
.sp12t-node.rot2 svg {transform: rotate(180deg);}
.sp12t-node.rot3 svg {transform: rotate(-90deg);}


.sp12t-node .diagram-id {position: absolute;}
.sp12t-node .label {position: absolute;}


.sp12t-node.rot0 .diagram-id {top: -227%; left:37%;}
.sp12t-node.rot2 .diagram-id {top: -227%; left: 23%;}
.sp12t-node.rot1 .diagram-id {left: -228%; top: 18%;}
.sp12t-node.rot3 .diagram-id {left: -225%; top: 62%;}

.sp12t-node.rot0 .label {top: 328%;}
.sp12t-node.rot2 .label {top: 328%;}
.sp12t-node.rot1 .label {left: 90%; top: 0%;}
.sp12t-node.rot3 .label {left: 90%; top: 76%;}

/* .sp12t-node.rot0, .sp12t-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.sp12t-node.rot1, .sp12t-node.rot3 {
  width: var(--height);
  height: var(--width);
}

.sp12t-node .diagram-id {
  padding-left: 5px;
} */
