.sp4t-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.sp4t-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.sp4t-node.rot1 svg text {transform: rotate(-90deg) translate(-60px, -50px);}
.sp4t-node.rot2 svg text {transform: rotate(-180deg) translate(-5px, -110px);}
.sp4t-node.rot3 svg text {transform: rotate(90deg) translate(50px, -54px);}

.sp4t-node svg {
  width: 100%;
}

.sp4t-node.rot0 svg {transform: rotate(0deg);}
.sp4t-node.rot1 svg {transform: rotate(90deg);}
.sp4t-node.rot2 svg {transform: rotate(180deg);}
.sp4t-node.rot3 svg {transform: rotate(-90deg);}


.sp4t-node .diagram-id {position: absolute;}
.sp4t-node .label {position: absolute;}


.sp4t-node.rot0 .diagram-id {top: -27%; left: 37%;}
.sp4t-node.rot2 .diagram-id {top: -25%; left: 23%;}
.sp4t-node.rot1 .diagram-id {right: -10%; top: 40%;}
.sp4t-node.rot3 .diagram-id {right: -5%; top: 40%;}

.sp4t-node.rot0 .label {top: 130%;}
.sp4t-node.rot2 .label {top: 130%;}
.sp4t-node.rot1 .label {left: -80%; top: 40%;}
.sp4t-node.rot3 .label {left: -80%; top: 40%;}
