.sp10t-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.sp10t-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px); text-anchor: start;}
.sp10t-node.rot1 svg text {transform: rotate(-90deg) translate(-154px, -146px); text-anchor: middle;}
.sp10t-node.rot2 svg text {transform: rotate(-180deg) translate(-5px, -301px); text-anchor: start;}
.sp10t-node.rot3 svg text {transform: rotate(90deg) translate(147px, -150px); text-anchor: end;}

.sp10t-node.rot0 svg {transform: rotate(0deg);}
.sp10t-node.rot1 svg {transform: rotate(90deg);}
.sp10t-node.rot2 svg {transform: rotate(180deg);}
.sp10t-node.rot3 svg {transform: rotate(-90deg);}


.sp10t-node .diagram-id {position: absolute;}
.sp10t-node .label {position: absolute;}


.sp10t-node.rot0 .diagram-id {top: -176%; left: 38%;}
.sp10t-node.rot2 .diagram-id {top: -176%; left: 22%;}
.sp10t-node.rot1 .diagram-id {left: -175%; top: 19%;}
.sp10t-node.rot3 .diagram-id {left: -177%; top: 62%;}

.sp10t-node.rot0 .label {top: 281%;}
.sp10t-node.rot2 .label {top: 281%;}
.sp10t-node.rot1 .label {left: 90%; top: 0%;}
.sp10t-node.rot3 .label {left: 90%; top: 76%;}

/* .sp10t-node.rot0, .sp10t-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.sp10t-node.rot1, .sp10t-node.rot3 {
  width: var(--height);
  height: var(--width);
}

.sp10t-node .diagram-id {
  padding-left: 5px;
} */
