.filter-node {
  width: 60px;
  height: 60px;
  display:flex;
  align-items:center;
  justify-content:center;

}

.filter-node.rot0 svg {transform: rotate(0deg);}
.filter-node.rot1 svg {transform: rotate(90deg);}
.filter-node.rot2 svg {transform: rotate(180deg);}
.filter-node.rot3 svg {transform: rotate(-90deg);}


.filter-node .diagram-id {position: absolute;}
.filter-node .label {position: absolute;}


.filter-node.rot0 .diagram-id {top: -10%;}
.filter-node.rot2 .diagram-id {top: -10%;}
.filter-node.rot1 .diagram-id {left: 80%; top: 0%}
.filter-node.rot3 .diagram-id {left: 80%; top: 0%}

.filter-node.rot0 .label {top: 70%;}
.filter-node.rot2 .label {top: 70%;}
.filter-node.rot1 .label {left: 80%; top: 70%;}
.filter-node.rot3 .label {left: 80%; top: 70%;}

/* .filter-node.rot0,.filter-node.rot2 {background: url('./icons/Cable_1.svg');}
.filter-node.rot1,.filter-node.rot3 {background: url('./icons/Cable_2.svg');}


.filter-node.rot0, .filter-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.filter-node.rot1, .filter-node.rot3 {
  width: var(--height);
  height: var(--width);
} */