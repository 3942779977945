.noPartText{
    text-shadow: 0 0 0.4rem #ff0808
}
.inlineIcon {
    display:flex;
    flex-direction: row;
}
.inlineIcon svg {
    height: 2em;
    width: 2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
kbd {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin: 0 0.2em;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
    font-size: 0.75rem;
    line-height: 1rem;
}