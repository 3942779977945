.unicpl-node {
  width: 120px;
  height: 120px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.unicpl-node.rot0 svg text {transform: rotate(0deg) translate(0px, 0px);}
.unicpl-node.rot1 svg text {transform: rotate(-90deg) translate(-31px, -25px);}
.unicpl-node.rot2 svg text {transform: rotate(-180deg) translate(-8px, -55px);}
.unicpl-node.rot3 svg text {transform: rotate(90deg) translate(23.5px, -30px);}


.unicpl-node svg {
  width: 100%;
}
.unicpl-node .rot0 .react-flow__handle-bottom {
  bottom: 17px;
}

.unicpl-node .rot2 .react-flow__handle-top {
  top: 17px;
}

.unicpl-node.rot0 svg {transform: rotate(0deg);}
.unicpl-node.rot1 svg {transform: rotate(90deg);}
.unicpl-node.rot2 svg {transform: rotate(180deg);}
.unicpl-node.rot3 svg {transform: rotate(-90deg);}


.unicpl-node .diagram-id {position: absolute;}
.unicpl-node .label {position: absolute;}


.unicpl-node.rot0 .diagram-id {top: 21%;}
.unicpl-node.rot2 .diagram-id {top: 60%;}
.unicpl-node.rot1 .diagram-id {left: 78%; top: 50%;}
.unicpl-node.rot3 .diagram-id {left: 65%; top: 40%;}

.unicpl-node.rot0 .label {left: 40%; top: 60%;}
.unicpl-node.rot2 .label {left: 40%; top: 73%;}
.unicpl-node.rot1 .label {left: 80%; top: 40%;}
.unicpl-node.rot3 .label {left: 67%; top: 40%;}

/* .unicpl-node.rot0, .unicpl-node.rot2 {
  width: var(--width);
  height: var(--height);
}
.unicpl-node.rot1, .unicpl-node.rot3 {
  width: var(--height);
  height: var(--width);
}
/*
.unicpl-node .diagram-id {
  padding-left: 5px;
} */
